export const leadership = [
  {
    name: "Hisham Mundol",
    description: (
      <>
        <p>
          Hisham Mundol is the Chief Advisor, India for Environmental Defense
          Fund, for operations in India. He started his career in sales,
          marketing and consulting with Hindustan Unilever, WorldTel, Accenture
          & Infosys. He subsequently moved to the development sector where he
          led the National Condoms Programme for the Indian Government’s
          National AIDS Control Organisation and later set up operations in
          South Asia for Wikimedia Foundation promoting projects like Wikipedia.
          Most recently, he led the work of the Children’s Investment Fund
          Foundation in India (across health, nutrition, adolescence, education,
          girls’ & women’s rights and livelihoods, child protection, and climate
          change.)
        </p>
      </>
    ),
    image: "/images/Hisham.webp",
  },
  {
    name: "Coen Kompier",
    description: (
      <>
        <p>
          Coen Kompier is Senior Specialist International Labour Standards,
          International Labour Office. He completed his Masters in International
          Law and International Relations from the University of Groningen,
          Netherlands. In 1994, he joined the International Labour Office, with
          short stints at ILO headquarters in Geneva (Switzerland) and its
          international training centre in Turin (Italy). Later her worked in
          ‘field’ positions in West and East Africa, and 16 years in South Asia
          where New Delhi was his home. He specializes in international labour
          standards, fundamental labour rights and human rights issues. He
          worked in more than fifty countries with state representatives, trade
          unions, business associations, and many other relevant actors in the
          world of work. He has also contributed to several publications dealing
          with exclusion and labour exploitation. He is currently based in
          Egypt, operating in North Africa and the Horn of Africa.
        </p>
      </>
    ),
    image: "/images/Coen-Kompier-1-1.webp",
  },
  {
    name: "Julian Parr",
    description: (
      <>
        <p>
          Julian has held senior regional management posts with the BBC, the UN,
          Oxfam GB and the CGIAR Group across Pakistan, Sri Lanka, Afghanistan,
          Bangladesh and India. His specialisms lie in corporate social
          responsibility, sustainable development, labour law in the informal
          sector and macro-economic issues in transitioning economies.
        </p>
      </>
    ),
    image: "/images/Julian-1.webp",
  },
  {
    name: "Katie Davies",
    description: (
      <>
        <p>
          Katie Davies is Program Manager at Ignite Philanthropy: Inspiring the
          End to Violence Against Girls and Boys, where she leads the Strategic
          Networks initiative, which convenes and supports regional and global
          networks working in the field to end sexual violence against children.
          Prior to joining Ignite Philanthropy in 2018, Katie served for over 6
          years at Stars Foundation, a UK-based funder investing in grassroots
          organisations working to strengthen children’s rights. Katie has also
          worked in a consultancy role supporting philanthropists to shift their
          investment strategies towards systems-change approaches and started
          her career in a policy and advocacy role at Oxfam Cymru (Wales),
          focused on domestic poverty and inequality. She holds an MA in
          International Development and Planning and a BSc (Hons) in Economics,
          both from the University of Sheffield, and is working towards
          completing an MSc in Grantmaking, Philanthropy and Social Investment
          from Bayes Business School (City, University of London).
        </p>
      </>
    ),
    image: "/images/Katie-Davies1.webp",
  },
  {
    name: "Sam Tiwari",
    description: (
      <>
        <p>
          Sam has worked with a wide range of Civil Society and International
          Development Organizations in the UK and India, empowering them to
          mobilize resources from and build transformational partnerships with
          Institutions and Individuals, including Governments, Financial
          Institutions, Foundations, Philanthropists and Investors. In her
          career spanning sixteen years in diverse cultural contexts, Sam has
          worked across sectors such as Microfinance, Education, Humanitarian
          Aid and Sexual and Reproductive Health. She currently heads up the
          external engagement with foundations and private donors at MSI
          Reproductive Choices.
        </p>
      </>
    ),
    image: "/images/Sam-Tiwari-1.webp",
  },
];
