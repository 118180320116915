import { useForm } from "react-hook-form";
import Input from "@/components/ui/input";
import SubmitButton from "@/components/ui/submit-button";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useEffect, useState } from "react";
import Image from "next/image";
const ContactForm = ({ status, message, onValidated }) => {
  const [show, setShow] = useState(false);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  function onSubmit(data) {
    setProcessing(true);
    data.email &&
      data.name &&
      data.email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: data.email,
        MERGE1: data.name,
        MERGE2: data.mobile,
        MERGE3: data.organisation,
        MERGE4: data.designation,
        MERGE5: data.message,
      });
  }

  useEffect(() => {
    if (status === "success") {
      clearFields();
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  }, [status]);

  const clearFields = () => {
    reset();
  };
  return (
    <div className="flex flex-col w-full ">
      <h2 className="text-darkBlue w-fit border-b-[2px] border-blue mb-2">
        Get Involved
      </h2>

      <form
        className="flex md:flex-row flex-col w-full"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="w-full flex flex-col h-full justify-start shrink-0">
          {status === "error" && (
            <div
              className="rounded md:p-12 px-4 py-8 md:text-base font-medium text-center text-red-500 text-red text-sm w-full mt-8 border-dashed border-[1px] border-red-500"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="text-center text-darkBlue text-sm w-full border-dashed border-[1px] border-darkBlue mt-8 rounded md:p-12 px-4 py-8 md:text-base font-medium"
              dangerouslySetInnerHTML={{
                __html: "Thank you for reaching out!",
              }}
            />
          )}
          {status !== "success" && status !== "error" && (
            <>
              <div className="w-full flex md:flex-row flex-col lg:gap-6 md:gap-8 gap-6 my-3">
                <Input
                  placeholder="Name*"
                  type="text"
                  variant="solid"
                  {...register("name", {
                    required: "Please provide your full name.",
                  })}
                  errorKey={errors.name?.message}
                />
                <Input
                  placeholder="Email*"
                  type="email"
                  variant="solid"
                  className="mb-4"
                  {...register("email", {
                    required: `${"Please provide your email address"}`,
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please provide a valid email address",
                    },
                  })}
                  errorKey={errors.email?.message}
                />
              </div>
              <div className="w-full flex md:flex-row flex-col lg:gap-6 md:gap-8 gap-6 my-3">
                <Input
                  placeholder="Mobile"
                  type="text"
                  variant="solid"
                  {...register("mobile")}
                  errorKey={errors.mobile?.message}
                />
                <Input
                  placeholder="Organisation"
                  type="text"
                  variant="solid"
                  {...register("organisation")}
                  errorKey={errors.organisation?.message}
                />
              </div>
              <div className="w-full flex md:flex-row flex-col lg:gap-6 md:gap-8 gap-6 my-3">
                <Input
                  placeholder="Designation"
                  type="text"
                  variant="solid"
                  {...register("designation")}
                  errorKey={errors.designation?.message}
                />
                <Input
                  placeholder="Message"
                  type="text"
                  variant="solid"
                  {...register("message")}
                  errorKey={errors.message?.message}
                />
              </div>

              <SubmitButton
                variant="blue"
                text={status === "sending" ? "Sending..." : `Submit`}
                className={"mt-5 mx-auto"}
                icon={false}
              />
            </>
          )}
        </div>
      </form>
    </div>
  );
};
const ContactUs = (props) => {
  const url = `https://dignityallianceinternational.us17.list-manage.com/subscribe/post?u=21522a8f9774d3866cba2ab67&id=ec25e1dcd3`;

  return (
    <div className="w-full z-[1] ">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <ContactForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default ContactUs;
