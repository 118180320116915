import React from "react";
import Image from "next/image";
import Section from "@/components/ui/section";
import ContactUs from "./contact-form";
export default function ContactUsSection() {
  return (
    <Section
      variant="flat"
      className="min-h-[calc(100vh-136px)] bg-black/[0.02]"
      id="get-in-touch"
    >
      <div className="flex lg:flex-row flex-col lg:gap-12 gap-6 w-full lg:py-12">
        <div className="relative lg:w-[400px] lg:h-[400px] md:w-full md:h-[250px] w-full h-[200px] shrink-0 rounded-2xl overflow-hidden">
          <Image
            src={"/images/GET INVOLVED.webp"}
            alt={"contact-banner"}
            fill
            priority={true}
            className="object-cover object-center w-full"
            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
          />
        </div>

        <ContactUs />
      </div>
    </Section>
  );
}
