import Section from "@/components/ui/section";
import React from "react";
import Card from "@/components/ui/card";
export default function Team({ title, data, className }) {
  return (
    <Section className={className} id="governance">
      <h2 className="text-darkBlue w-fit border-b-[2px] border-blue mb-2 mx-auto">
        {title}
      </h2>

      <div className="mt-8 grid grid-rows-auto lg:grid-cols-5 md:grid-cols-3 grid-cols-2 md:gap-8 gap-4">
        {data.map((member, key) => (
          <Card data={member} key={"title" + key} />
        ))}
      </div>
    </Section>
  );
}
