import React from "react";
import Section from "@/components/ui/section";
import Image from "next/image";
import cn from "classnames";
export default function WhatWeDo({
  reverse,
  title,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  image,
  id,
}) {
  return (
    <Section className={"bg-[#F4F9FF]"} variant="flat" id={id}>
      <div
        className={cn("flex lg:gap-12 gap-6 w-full mx-auto", {
          "lg:flex-row-reverse flex-col-reverse items-center": reverse,
          "lg:flex-row flex-col-reverse items-center": !reverse,
        })}
      >
        <div className="flex flex-col gap-3">
          <h2 className="text-darkBlue w-fit border-b-[2px] border-blue mb-2">
            {title ?? "The Problem"}
          </h2>
          {descriptionOne && (
            <div
              className="text-body md:text-base text-sm text-justify"
              dangerouslySetInnerHTML={{ __html: descriptionOne }}
            />
          )}
          {descriptionTwo && (
            <div
              className="text-body md:text-base text-sm text-justify"
              dangerouslySetInnerHTML={{ __html: descriptionTwo }}
            />
          )}
          {descriptionThree && (
            <div
              className="text-body md:text-base text-sm text-justify"
              dangerouslySetInnerHTML={{ __html: descriptionThree }}
            />
          )}
        </div>
        <div className="relative lg:w-[400px] lg:h-[400px] md:w-full md:h-[250px] w-full h-[200px] shrink-0 rounded-2xl overflow-hidden">
          <Image
            src={image}
            alt={title}
            fill
            className="object-contain object-left w-full rounded-2xl"
            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px "
          />
        </div>
      </div>
    </Section>
  );
}
