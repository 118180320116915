import React from "react";

const Input = React.forwardRef(
  (
    {
      className = "block w-full",
      labelKey,
      name,
      errorKey,
      placeholderKey,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      setError,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="w-full">
        {labelKey && (
          <label
            htmlFor={name}
            className={`block text-darkGrey font-body font-normal text-sm leading-none mb-2`}
          >
            {labelKey}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          // @ts-ignore
          placeholder={placeholderKey}
          className={
            "w-full shrink-0 lg:py-3 py-2 bg-transparent placeholder:text-darkGrey text-sm focus:outline-none outline-none text-body border-b-[1.5px] border-darkBlue"
          }
          autoComplete="off"
          spellCheck="false"
          aria-invalid={errorKey ? "true" : "false"}
          {...rest}
        />
        {errorKey && <p className="text-xs mt-1 text-red-500">{errorKey}</p>}
      </div>
    );
  }
);
Input.displayName = "Input";
export default Input;
