import React from "react";
import { motion } from "framer-motion";
import cn from "classnames";
export default function Section({
  children,
  className,
  variant = "normal",
  id,
}) {
  return (
    <motion.div
      // initial={{ opacity: 0, y: 80 }}
      // whileInView={{ opacity: 1, y: 0 }}
      // transition={{ duration: 0.65 }}
      // viewport={{ once: true }}
      id={id}
      className={cn(
        ` w-full h-auto flex flex-col z-0`,
        {
          "lg:py-24 py-16 px-4 md:px-12 lg:px-16": variant === "normal",
          "py-10 px-4 md:px-12 lg:px-16": variant === "flat",
          "lg:py-24 md:py-16 py-6 pl-4 md:pl-12 lg:pl-16":
            variant === "carousel",
          "py-0 px-4 md:px-12 lg:px-16": variant === "flatZero",
          "py-0 pl-4 md:pl-12 lg:pl-16": variant === "flatZeroCarousel",
          "lg:py-8 py-6 pl-4 md:pl-12 lg:pl-16": variant === "categories",
        },
        className
      )}
    >
      <div className={`w-full flex flex-col`}>{children}</div>
    </motion.div>
  );
}
