import Section from "@/components/ui/section";
import Image from "next/image";
import React from "react";

export default function Reports() {
  const reports = [
    {
      image: "/images/report-1.webp",
      link: "/pdf/Sexual-Violence-in-South-Asia.pdf",
      description:
        "Sexual Violence in South Asia report focuses on the problems that women and girls face while accessing the criminal justice system.",
    },
    {
      image: "/images/report-2.webp",
      link: "/pdf/Bhutan-Govt.pdf",
      description:
        "The Ministry of Foreign Affairs, Royal Government of Bhutan shares Sexual Violence in South Asia report with various departments.",
    },
    {
      image: "/images/report-3.webp",
      link: "https://www.theguardian.com/global-development/2021/apr/23/victims-in-south-asia-still-face-vaginal-tests-report-finds",
      description:
        "Physical vaginal tests are still used to determine whether women and girls have been raped.",
    },
    {
      image: "/images/report-4.webp",
      link: "http://www.ipsnews.net/2021/04/rights-group-calls-overhaul-criminal-justice-systems-response-sexual-violence-across-south-asia/",
      description:
        "Survivors face threats, pressure to settle out of court and obstacles to justice from systems rife with implementation failures. ",
    },
  ];
  return (
    <Section variant="flat" className=" bg-[#F4F9FF]">
      <h2 className="text-darkBlue text-center w-fit mx-auto border-b-[2px] border-blue mb-2">
        Reports & Publication
      </h2>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full md:gap-6 gap-12 lg:my-10 my-8">
        {reports?.map((row, key) => (
          <Report key={"report" + key} data={row} />
        ))}
      </div>
    </Section>
  );
}
const Report = ({ data }) => {
  const { description, image, link } = data;
  return (
    <div className="w-full flex flex-col justify-start items-center gap-4 ">
      <div className="w-full lg:h-[30vw] md:h-[45vh] h-[480px] rounded-2xl overflow-hidden flex flex-col shrink-0 justify-center items-center transition-all duration-300 ease relative">
        <Image
          src={image}
          alt={"icon"}
          fill
          className="object-cover object-center w-full"
          sizes="(max-width: 479px) 479px,(max-width: 768px) 767px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1920px "
        />
      </div>
      <div className="flex flex-col w-full gap-2">
        <div
          className="lg:text-base text-sm text-body mb-0 text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
          className="w-fit mx-auto bg-darkBlue hover:bg-blue transition-all duration-300 ease text-sm text-white text-center px-4 py-2 rounded-full"
        >
          read more
        </a>
      </div>
    </div>
  );
};
