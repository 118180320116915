import { motion } from "framer-motion";
import { Fragment, useRef, useState } from "react";
import Image from "next/image";

export default function Card({ data }) {
  const { image, description, name } = data;
  const [isCardOpened, setIsCardOpened] = useState(false);
  const [cardDimensions, setCardDimensions] = useState({ width: 0, height: 0 });
  const card = useRef(null);
  // useScrollLock(isCardOpened);
  return (
    <Fragment>
      <motion.div
        ref={card}
        layout
        className={`${
          isCardOpened ? "shadow-card" : "shadow-0"
        } overflow-hidden cursor-pointer ${
          isCardOpened
            ? "fixed top-8 left-0 right-0 bottom-0 m-auto z-[20] flex flex-col justify-start p-4 lg:w-[50vw] w-[95vw] h-[500px] bg-[#F4F9FF] rounded-2xl overflow-y-auto"
            : "relative"
        }`}
      >
        {isCardOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute lg:top-4 top-3 lg:right-8 right-3 cursor-pointer"
          >
            <Image
              src="/images/cancel.png"
              alt="close"
              className="lg:w-8 w-6"
              onClick={() => {
                console.log("clicked");
                setIsCardOpened(!isCardOpened);
              }}
              width={40}
              height={40}
            />
          </motion.div>
        )}
        <motion.div
          onClick={() => {
            setIsCardOpened(true);
            if (!isCardOpened) {
              setCardDimensions({
                width: card.current.clientWidth,
                height: card.current.clientHeight,
              });
            }
          }}
          className={` ${
            isCardOpened
              ? "w-[200px] lg:h-[200px] md:h-[200px] h-[200px]"
              : "w-full lg:h-[230px] md:h-[200px] h-[170px]"
          } object-cover rounded-full relative overflow-hidden`}
        >
          <Image
            src={image}
            alt={name}
            fill
            className="w-full h-full"
            sizes="(max-width: 479px) 479px,(max-width: 768px) 479px, (max-width: 1200px) 479px,(max-width: 1920px) 479px, 479px"
          />
        </motion.div>
        <div className="flex flex-col w-full">
          <motion.div
            layout="position"
            className={`lg:text-lg text-base ${
              isCardOpened ? "text-left" : "text-center"
            } font-bold my-2 text-darkBlue`}
          >
            {name}
          </motion.div>

          {isCardOpened && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-sm font-light"
            >
              {description}
            </motion.p>
          )}
        </div>
      </motion.div>
      {isCardOpened && (
        <Fragment>
          <div
            style={{
              width: cardDimensions.width,
              height: cardDimensions.height,
            }}
          ></div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={() => setIsCardOpened(false)}
            className="fixed inset-0 bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-10"
          />
        </Fragment>
      )}
    </Fragment>
  );
}
