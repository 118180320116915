import { useState } from "react";
import Image from "next/image";
import Slick from "@/components/ui/carousel/slick";
const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      image: "/images/banner-1.webp",
    },
    {
      image: "/images/banner-2.webp",
    },
    {
      image: "/images/banner-3.webp",
    },
  ];

  return (
    <div className="homeBanner relative mb-0 mx-auto overflow-hidden bg-white w-full lg:h-screen md:h-[800px] h-[500px]">
      <Slick
        dots={true}
        navigation={false}
        slidesToShow={1}
        fade={true}
        onSlideChange={setCurrentSlide}
      >
        {data?.map((row, key) => (
          <div
            key={"banner" + key}
            className="w-full lg:h-screen md:h-[800px] h-[500px] relative"
          >
            <Image
              src={row?.image}
              alt={"banner" + key}
              fill
              className="object-cover object-center"
              loading={"eager"}
              priority={true}
              sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
            />
          </div>
        ))}
      </Slick>
    </div>
  );
};

export default Banner;
