import Banner from "@/components/pages/home/banner";
import HowWeDo from "@/components/pages/home/how-we-do";
import Reports from "@/components/pages/home/reports";
import WhatWeDo from "@/components/pages/home/what-we-do";
import Section from "@/components/ui/section";
import Team from "@/components/pages/home/team";
import { leadership } from "@/data/team";
import ContactUsSection from "@/components/pages/home/contact-us";
export default function Home() {
  return (
    <>
      <Banner />
      <Section variant="flat">
        <h1 className="lg:max-w-[800px] mx-auto py-12 px-4 text-white bg-darkBlue rounded-[20px] lg:text-lg md:text-base text-sm text-center">
          <strong className="text-blue">Dignity Alliance International</strong>{" "}
          (DAI) is a UK-based charity committed to ending violence against women
          and children, and all forms of modern slavery across South Asia.
        </h1>
      </Section>
      <WhatWeDo
        id="what-we-do"
        title="What We Do"
        image="/images/map.webp"
        descriptionOne="DAI invests in those that seek to ensure access to justice for excluded communities and to a more just and equitable society. We support the development of South Asian networks and community leaders that strive to build and grow movements for social justice."
        descriptionTwo="We partner with organisations across South Asia to protect the most vulnerable, to liberate and reintegrate the enslaved, and to prosecute those responsible. We support campaigns that build support for the eradication of violence and slavery."
      />
      <HowWeDo />
      <Reports />
      <Team title="Managing Board" data={leadership} className="" />
      <ContactUsSection />
    </>
  );
}
