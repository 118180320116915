import Section from "@/components/ui/section";
import Image from "next/image";
import React from "react";

export default function HowWeDo() {
  const points = [
    {
      icon: "/images/collaborate.png",
      description:
        "DAI funds community-based organisations and survivor-led groups to tackle issues of violence against women and children, forced labour and reform the justice system.",
    },
    {
      icon: "/images/problem-solving.png",
      description:
        "We commission evidence-based research to understand and identify the underlying, unjust systems which perpetuate modern slavery practices and human rights violations across South Asia.",
    },
    {
      icon: "/images/preventive.png",
      description:
        "We invest in the professional development of human rights defenders and lawyers from socially excluded communities to sustain and expand their work.",
    },
    {
      icon: "/images/writing.png",
      description:
        "We strive to ensure the experience and knowledge of survivors and human rights defenders are reflected in national policy making.",
    },
    {
      icon: "/images/impact.png",
      description:
        "We are committed to ensuring individuals and organisations document their lived experiences and collaboratively develop strategies and solutions to drive change.",
    },
    {
      icon: "/images/consolidate.png",
      description:
        "We commission or support projects as per our program cycle, further details will be updated on the website. We don't accept unsolicited applications or proposals for support.",
    },
  ];
  return (
    <Section id="how-we-do-this">
      <h2 className="text-darkBlue text-center w-fit mx-auto border-b-[2px] border-blue mb-2">
        How we do this
      </h2>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-6 lg:mt-10 mt-8">
        {points?.map((point, key) => (
          <HeaderIconBox key={"iconBox" + key} data={point} />
        ))}
      </div>
    </Section>
  );
}
const HeaderIconBox = ({ data }) => {
  const { description, icon } = data;
  return (
    <div className="shadow-card w-full flex flex-col justify-start items-center gap-4 p-4 rounded-2xl bg-white group hover:bg-white hover:shadow-artist border-[1px] border-white hover:border-darkBlue transition-all duration-300 ease">
      <div className="lg:h-24 h-20 lg:w-24 w-20 rounded-2xl flex flex-col shrink-0 justify-center items-center bg-[#F4F9FF] transition-all duration-300 ease relative">
        <Image
          src={icon}
          alt={"icon"}
          fill
          className="object-contain object-center w-full p-5"
          sizes="(max-width: 479px) 200px,(max-width: 768px) 200px, (max-width: 1200px) 200px,(max-width: 1920px) 200px, 200px "
        />
      </div>
      <div className="flex flex-col w-full gap-2">
        {/* <p className="lg:text-lg text-base text-darkBlue font-bold text-center">
            {heading}
          </p> */}
        <div
          className="lg:text-base text-sm text-body mb-0 text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};
