import React from "react";
import cn from "classnames";
import Image from "next/image";
export default function SubmitButton({
  className,
  icon = true,
  variant = "blue",
  text = "Read More",
  link = "#",
  anchor = false,
  type = "button",
}) {
  return (
    <button type="submit" className={`relative w-fit group ${className}`}>
      <div
        className={cn(
          {
            "border-darkBlue bg-darkBlue hover:bg-blue hover:border-blue":
              variant == "blue",
            "border-white bg-teal": variant == "white",
            "border-white bg-body": variant == "whiteBody",
            "border-body bg-yellow": variant == "body",
          },
          `rounded-full w-fit px-11 py-3 flex items-center justify-center gap-2 z-[1] relative border-[1px] transition-all duration-300 ease`
        )}
      >
        <p
          className={cn(
            {
              "text-white":
                variant == "blue" ||
                variant == "white" ||
                variant == "whiteBody",
              "text-body": variant == "body",
            },
            "text-[14px] font-roboto font-medium"
          )}
        >
          {text}
        </p>
        {icon && (
          <div className="w-4 h-4 relative">
            <Image
              src={"/images/icons/right-arrow.svg"}
              alt={"email icon"}
              fill
              className="object-contain object-left"
              quality={100}
              priority={true}
              sizes="(max-width: 479px) 100px,(max-width: 768px) 100px, (max-width: 1200px) 100px,(max-width: 1920px) 100px, 100px"
            />
          </div>
        )}
      </div>
    </button>
  );
}
